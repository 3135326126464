import Link from "next/link";
import Image from "next/image";

export default function Logo() {
  return (
    <Link className="block" href="/" aria-label="Lavareach">
      <div className="text-lg flex flex-row items-end gap-2 font-bold">
        <Image
          src={"/lavareach_logo.png"}
          width={38}
          height={38}
          priority
          alt="LavaReach Logo"
        />
        <div>
          Lava<span className="text-lavareachOG">Reach</span>
        </div>
      </div>
    </Link>
  );
}
