import Link from "next/link";
import Logo from "./logo";
import ShinyButton from "./shiny-login-button";

export default function LandingPageHeader() {
  // TODO: Make the header responsive
  return (
    <header className="w-full z-30 sticky top-0 bg-white h-[80px] border-b">
      <div className="max-w-7xl mx-auto px-lg sm:px-xl">
        <div className="flex flex-col gap-sm pt-2xl">
          {/* <EventBanner /> */}
          <nav className="flex items-center justify-between">
            {/* Site branding */}
            <div className="flex grow justify-between align-text-bottom font-semibold">
              <div className="flex justify-between sm:justify-start gap-2xl sm:gap-4xl lg:gap-5xl items-center">
                <div className="-mt-[8px]">
                  <Logo />
                </div>
                {/* <div className="hidden sm:flex">
                  <UseCasesNavigationMenu />
                </div> */}
                {/* <Link
                  className="hidden md:flex text-center text-slate-300 hover:text-white"
                  href="/for-marketing-agencies"
                >
                  For Marketing Agencies
                </Link> */}
                <Link
                  className="hidden sm:flex text-text-landing hover:underline"
                  href="/blog"
                >
                  Blog
                </Link>

                <Link
                  className="hidden sm:flex text-text-landing hover:underline"
                  href="/pricing"
                >
                  Pricing
                </Link>
              </div>

              <div className="gap-4xl lg:gap-5xl flex justify-between items-center">
                <Link className="hidden sm:flex" href="/schedule">
                  <span className="text-lavareachOG hover:underline">
                    Contact Founder
                  </span>
                </Link>
                <Link href="/dashboard">
                  <ShinyButton />
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export function MinimalisticHeader() {
  return (
    <header className="w-full z-30 sticky top-0 bg-[#222129]">
      <div className="max-w-7xl mx-2xl lg:mx-auto px-lg sm:px-xl">
        <nav className="flex items-center justify-between py-lg md:py-2xl">
          {/* Site branding */}
          <div className="flex grow justify-between align-text-bottom font-semibold">
            <div className="flex justify-between sm:justify-start gap-2xl sm:gap-4xl lg:gap-5xl items-center">
              <div className="-mt-[8px]">
                <Logo />
              </div>
            </div>
            <div className="gap-4xl lg:gap-5xl flex justify-between items-center">
              <Link href="/dashboard">
                <ShinyButton />
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
