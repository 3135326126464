"use client";

import "../css/globals.css";
import { Suspense, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/globals.css";
import LandingPageHeader from "@/components/ui/header";
import { PHProvider } from "../(app)/dashboard/posthog-provider";
import PostHogPageView from "../(app)/dashboard/posthog-pageview";
import LandingPageFooter from "@/components/ui/footer";

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  });

  return (
    <body
      className={`font-inter antialiased bg-white text-text-landing h-screen overflow-hidden`}
    >
      <PHProvider>
        <Suspense>
          <PostHogPageView />
        </Suspense>
      </PHProvider>
      <div>
        <LandingPageHeader />
        <div className="flex flex-col h-[calc(100vh-100px)] justify-between overflow-y-auto">
          <main className="items-center justify-center grow">{children}</main>
          <LandingPageFooter />
        </div>
      </div>
    </body>
  );
}
